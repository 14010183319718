import React from 'react'
import {graphql} from 'gatsby'

import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../lib/helpers'
import BlogPostPreviewList from '../components/blog-post-preview-list'
import Container from '../components/container'
import Hereo from '../components/hereo'
import ContactForm from '../components/contact-form'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'

import cases from '../content/cases.json'
import services from '../content/services.json'

import s from './index.module.css'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query IndexPageQuery {
    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      title
      description
      keywords
    }
    posts: allSanityPost(
      limit: 3
      sort: {fields: [publishedAt], order: DESC}
      filter: {slug: {current: {ne: null}}, publishedAt: {ne: null}}
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const IndexPage = props => {
  const {data, errors} = props
  const staticAssetsUrl = 'https://itify.s3.eu-north-1.amazonaws.com'

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <Hereo
        title='Unlock Digital Opportunities'
        videoUrl='https://pertineovid.s3-eu-west-1.amazonaws.com/hereo.mp4'
      />
      <div className={s.about}>
        <Container>
          Itify is a digital consultancy, operating in the intersection of business and tech
          <button className={s.contactButton} onClick={() => (window.location.href = '#contact')}>
            Get in touch
          </button>
        </Container>
      </div>
      <Container>
        <div className={s.logos}>
          <img
            src={`${staticAssetsUrl}/assets/img/clogo-mivai.png`}
            width='110'
            className={s.brandimg}
          />
          <img
            src={`${staticAssetsUrl}/assets/img/clogo-oslo.png`}
            width='60'
            className={s.brandimg}
          />
          <img
            src={`${staticAssetsUrl}/assets/img/clogo-nettavisen.png`}
            width='110'
            className={s.brandimg}
          />
          <img
            src={`${staticAssetsUrl}/assets/img/clogo-dn.png`}
            width='110'
            className={s.brandimg}
          />
          <img
            src={`${staticAssetsUrl}/assets/img/clogo-gjensidige.png`}
            width='120'
            className={s.brandimg}
          />
          <img
            src={`${staticAssetsUrl}/assets/img/clogo-vegvesen.png`}
            width='120'
            className={s.brandimg}
          />
        </div>
        <div className={s.clientText}>
          We help <span className={s.mark}>enterprises</span>,&#160;
          <span className={s.mark}>government</span> and&#160;
          <span className={s.mark}>progressive startups</span> realize digital opportunities
        </div>
      </Container>
      <div className={s.sectionServices}>
        <Container>
          <div className={s.services}>
            {services.map((service, index) => (
              <div className={s.serviceItem} key={index}>
                <h3>{service.title}</h3>
                <p>{service.text}</p>
              </div>
            ))}
          </div>
        </Container>
      </div>
      {false && (
        <Container>
          {postNodes && (
            <BlogPostPreviewList
              title='Latest blog posts'
              nodes={postNodes}
              browseMoreHref='/archive/'
            />
          )}
        </Container>
      )}
      <div>
        <Container className={s.sectionProjects}>
          <h2 className={s.title}>Projects</h2>
          <div className={s.projects}>
            {cases.map((c, index) => (
              <div className={s.caseItem} key={index}>
                <img className={s.image} src={c.image} alt='Illustration' />
                <h3>{c.title}</h3>
                <p>{c.text}</p>
              </div>
            ))}
          </div>
        </Container>
      </div>
      <div className={s.sectionContact} id='contact'>
        <Container>
          <h2 className={s.title}>Get in touch</h2>

          <div className={s.contactPitch}>
            We have delivered digital consulting services since 1997. Currently we're best at
            working full stack in the cloud with TypeScript/JS, React, C# .Net, Python and Swift.
          </div>

          <ContactForm />
        </Container>
      </div>
    </Layout>
  )
}

export default IndexPage
