import cs from 'classnames'
import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPhone, faEnvelope} from '@fortawesome/free-solid-svg-icons'
import {faLinkedin} from '@fortawesome/free-brands-svg-icons'

import s from './contact-form.module.css'

export default class MyForm extends React.Component {
  constructor (props) {
    super(props)
    this.submitForm = this.submitForm.bind(this)
    this.state = {
      status: ''
    }
  }

  render () {
    const {status} = this.state
    return (
      <div className={s.contact}>
        <div className={s.contactInfo}>
          <span className={cs(s.contactPhone, s.contactItem)}>
            <FontAwesomeIcon icon={faPhone} /> (+47) 4828 5555
          </span>
          <span className={cs(s.contactEmail, s.contactItem)}>
            <FontAwesomeIcon icon={faEnvelope} />
            &#160;
            <a href='mailto:&#112;&#111;&#115;&#116;&#064;&#105;&#116;&#105;&#102;&#121;&#046;&#110;&#111;'>
              &#112;&#111;&#115;&#116;&#064;&#105;&#116;&#105;&#102;&#121;&#046;&#110;&#111;
            </a>
          </span>
          <span className={cs(s.contactLinkedin, s.contactItem)}>
            <FontAwesomeIcon icon={faLinkedin} />
            &#160;
            <a href='https://www.linkedin.com/in/startupviking/'>LinkedIn</a>
          </span>
        </div>
        <form
          className={s.contactForm}
          onSubmit={this.submitForm}
          action='https://formspree.io/mknqrnly'
          method='POST'
        >
          <div className={s.formItem}>
            <label>Name:</label>
            <input type='text' name='name' />
          </div>
          <div className={s.formItem}>
            <label>Company:</label>
            <input type='text' name='company' />
          </div>
          <div className={s.formItem}>
            <label>Email:</label>
            <input type='email' name='email' />
          </div>
          <div className={s.formItem}>
            <label>Phone:</label>
            <input type='text' name='phone' />
          </div>
          <div className={cs(s.formItem, s.spanCols2)}>
            <label>Message:</label>
            <textarea type='text' name='message' />
          </div>
          <div className={cs(s.formItem, s.spanCols2)}>
            {status === 'SUCCESS' ? <p>Thanks!</p> : <button className={s.submit}>Send</button>}
            {status === 'ERROR' && <p>Ooops! There was an error.</p>}
          </div>
        </form>
      </div>
    )
  }

  submitForm (ev) {
    ev.preventDefault()
    const form = ev.target
    // eslint-disable-next-line no-undef
    const data = new FormData(form)
    // eslint-disable-next-line no-undef
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader('Accept', 'application/json')
    xhr.onreadystatechange = () => {
      // eslint-disable-next-line no-undef
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        form.reset()
        this.setState({status: 'SUCCESS'})
      } else {
        this.setState({status: 'ERROR'})
      }
    }
    xhr.send(data)
  }
}
