import React from 'react'

import s from './hereo.module.css'

const Hereo = ({videoUrl, title, text, actionText, actionUri, titleSeoBoost}) => {
  const titleElement = titleSeoBoost ? (
    <h1 className={s.title}>{title}</h1>
  ) : (
    <div className={s.title}>{title}</div>
  )

  return (
    <div className={s.root}>
      <video className={s.video} autoPlay muted loop id='myVideo'>
        <source src={videoUrl} type='video/mp4' />
      </video>
      <div className={s.content}>
        {titleElement}
        <div className={s.ingress}>
          <div className={s.text}>{text}</div>
          {actionText && (
            <button
              className={s.actionBtn}
              onClick={() => {
                document.location = actionUri
              }}
            >
              {actionText}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default Hereo
